import { Box } from '@air/zephyr';
import styled from 'styled-components';

export const TablePageContainer = styled(Box)`
  width: 100%;
  position: relative;

  .ReactVirtualized__Grid.ReactVirtualized__List {
    flex: 1;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    margin: auto;
    overflow-x: auto !important;

    // hide horizontal scrollbar
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  // change height of empty state to not interfere with sticky header
  section {
    height: auto;
  }
`;

/**
 * These are declared twice like this because Tailwind can't handle dynamic classes
 * @see https://air-labs-team.slack.com/archives/C021YL20RH8/p1678852155120919?thread_ts=1678828856.949449&cid=C021YL20RH8
 */
export const TABLE_ROW_HEIGHT = 56;
export const TABLE_ROW_HEIGHT_CLASS = 'h-14';

export const TABLE_CELL_H_PADDING = 16;
export const TABLE_CELL_V_PADDING = 8;
export const TableCell = styled(Box)<{ width?: number; minWidth?: number }>`
  flex: ${({ width }) => (width ? `1 0 ${width}px` : `1 1 100%`)};
  ${({ width, minWidth = width }) => minWidth && `min-width: ${minWidth}px`};

  padding: ${TABLE_CELL_H_PADDING}px;
  width: 100%;
  user-select: none;

  align-items: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
