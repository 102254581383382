import { ConfirmationModal } from '@air/component-confirmation-modal';
import { DateInput } from '@air/primitive-date-input';
import pluralize from 'pluralize';
import { memo, useCallback, useMemo, useState } from 'react';

import { parseAirDateToISO } from '~/utils/DateUtils';

import { BulkEditCustomFieldModalProps } from './types';

export interface BulkEditDateValueCustomFieldModalProps extends BulkEditCustomFieldModalProps {
  onSave: (params: {
    assetIds: string[];
    boardIds: string[];
    baseCustomField: BulkEditCustomFieldModalProps['field'];
    nextDateValue: string | undefined;
  }) => Promise<void>;
}

export const BulkEditDateValueCustomFieldModal = memo(
  ({
    field,
    assetIds,
    boardIds,
    initialValues,
    onClose,
    onSave,
  }: AirModalProps<BulkEditDateValueCustomFieldModalProps>) => {
    const allHaveSameValue = useMemo(() => {
      const baseValue = initialValues[0]?.dateValue;
      return initialValues.every((cf) => cf.dateValue === baseValue);
    }, [initialValues]);

    const originalValue = allHaveSameValue ? initialValues[0]?.dateValue : undefined;
    const originalDateValue = originalValue ? parseAirDateToISO(originalValue) : undefined;
    const [dateValue, setDateValue] = useState<Date | undefined>(originalDateValue);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onConfirm = useCallback(async () => {
      setIsSubmitting(true);
      const nextDateValue = dateValue?.toISOString();

      await onSave({
        assetIds,
        boardIds,
        baseCustomField: field,
        nextDateValue,
      });
      onClose();
    }, [assetIds, boardIds, dateValue, field, onClose, onSave]);

    return (
      <ConfirmationModal
        id="bulk-edit-date-value-custom-field-modal"
        isOpen
        title={`Edit ${field.name}`}
        description={`Select a date to apply to the ${pluralize(
          'item',
          assetIds.length + boardIds.length,
          true,
        )} you’ve selected.`}
        ctaPrimaryText="Save"
        onConfirm={onConfirm}
        isLoading={isSubmitting}
        isDisabled={dateValue === originalDateValue || isSubmitting}
        onDismiss={onClose}
      >
        <div className="mt-4">
          <DateInput
            size="large"
            selected={dateValue}
            placeholderText="Add a date"
            onChange={setDateValue}
            id={field.id}
            name={field.name}
            className="w-full"
          />
        </div>
      </ConfirmationModal>
    );
  },
);
BulkEditDateValueCustomFieldModal.displayName = 'BulkEditDateValueCustomFieldModal';
